<template>
    
    <div v-if="load==true">
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <div style="width: auto; margin: auto auto;">
            <br>
            <v-card>
                <v-card-title>
                    <span class="headline">Assign Role Level to User</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" sm="4">
                                <v-autocomplete v-model="seluser" :items="users" return-object item-text="username" label="Select User" prepend-icon="mdi-account" />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <br />
                                <v-chip outlined pill v-if="seluser.usertype">
                                    <v-icon size="20" left color="black">mdi-account</v-icon>
                                    User Type Of {{seluser.username}} :: {{seluser.usertype}}
                                </v-chip>
                            </v-col>

                            <v-col cols="12" md="12">
                                <div v-if="rolelevel.length > 0">
                                    Role Level 
                                    <v-checkbox v-for="item in rolelevel" :key="item.id" v-model="addrolelevel" :label="item.name" :value="item.id" color="success" @change="checkdropdownrequired()"></v-checkbox>
                                    <span v-if="addrolelevel.length ==0">
                                        <v-chip outlined pill>
                                            <v-icon left color="red">mdi-exclamation-thick</v-icon>
                                            Please select Role Level
                                        </v-chip>
                                    </span>
                                </div>
                                <v-btn v-if="!dropdownrequiredboolean" :disabled="addrolelevel.length < 1" color="indigo darken-4" dark class="mb-2" @click="addRoleLevelToUser">Add Role Level </v-btn>
                                <v-btn v-else :disabled="addrolelevel.length < 1" color="indigo darken-4" dark class="mb-2" @click="getDropdownDetails">Proceed </v-btn>
                            </v-col>
                            <v-col cols="12" md="8" v-if="roleleveldetails.length > 0 && dropdownrequiredboolean">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Role level</th>
                                                <th class="text-left">Select</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in roleleveldetails" :key="item.id">
                                                <td><b>{{ item.name }}</b></td>
                                                <td>
                                                    <v-autocomplete v-if="item.dropdownpresent" autocomplete="off" :items="item.dropdown" item-text="name" item-value="id" v-model="item.val"></v-autocomplete>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-btn color="indigo darken-4" dark class="mb-2" @click="addRoleLevelToUser">Add Role Level </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>

            <br>
            <v-card v-if="curr_rolelevel.length > 0">
                <v-card-title>
                    <span>Role Level of User {{seluser.username}}</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td colspan="3" align="right">
                                        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details style="width: 40%;"></v-text-field>
                                    </td>
                                </tr>
                                <br />
                                <tr>
                                    <th class="text-left">Role level</th>
                                    <th class="text-left">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in curr_rolelevel" :key="item.rolelevelid">
                                    <td>{{ item.name }}</td>
                                    <td>
                                        <div>
                                            <v-avatar style="" size="25" class="delete-avatar">
                                                <v-icon class="mr-2 delete-v-icon" color="red"  @click="deleteRoleGroupToUser(item.rolegroupuserid)" title="Delete Role Level">delete</v-icon>
                                            </v-avatar>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return{
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search:'',
            load:false,
            seluser:{
                username:'',
                usertype:''
            },

            addrolelevel:[],
            roleleveldetails : [],
            dropdownrequired : [],
            dropdownrequiredboolean : false,
            seluser_usertype:'',
            selmodule:'',
            users:{},
            modules:[],
            rolelevel:{},
            curr_rolelevel:{},
            department:[]
        }
    },

    mounted() {
        this.onload()
    },

    watch:{
        seluser(){
            this.getrolelevel() 
        },
        selmodule(){
            this.getrolelevel()
        }, 
    },

    methods:{
        onload(){
            axios
                .post("/AdminUser/roleleveltouser")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.users = res.data.users
                        this.load = true
                    } 
                })
                .catch(error => {
                    window.console.log(error);
                });
        },

        getrolelevel(){
            if((this.seluser.username!='')){
                const req_data={
                    username:this.seluser.username,
                }
                axios
                    .post("/AdminUser/getuserrolelevel",req_data)
                    .then(res => {
                        if (res.data.msg == "200") {
                            this.rolelevel = res.data.rolelevel;
                            this.curr_rolelevel = res.data.curr_rolelevel;
                            this.department = res.data.department;
                            this.dropdownrequired = res.data.dropdownrequired;
                        } 
                    })
                    .catch(error => {
                        window.console.log(error);
                    }); 
            }
        },

        checkdropdownrequired(){
            this.dropdownrequiredboolean = false
            for(var i = 0; i <this.dropdownrequired.length; i++ ){
                for(var j = 0; j < this.addrolelevel.length; j++){
                    if(this.dropdownrequired[i] == this.addrolelevel[j])
                        this.dropdownrequiredboolean = true
                }
            }
        },

        getDropdownDetails(){
            const req_data={
                username:this.seluser.username,
                rolelevelid:this.addrolelevel
            }
            axios
                .post("/AdminUser/getRoleLevelDropdown",req_data)
                .then(res => {
                    if (res.data.msg == "200") {
                       this.roleleveldetails = res.data.roleleveldetails;
                    }
                })
                .catch(error => {
                    window.console.log(error);
                }); 
        },

        addRoleLevelToUser(){
            const req_data={
                username:this.seluser.username,
                rolelevelid : this.addrolelevel,
                roleleveldetails: this.roleleveldetails,
                dropdownrequiredboolean : this.dropdownrequiredboolean,
            }
            axios
                .post("/AdminUser/assignrolelevel",req_data)
                .then(res => {
                    if (res.data.msg == "200") {
                        this.addrolelevel=[];
                        this.roleleveldetails = [];
                        this.dropdownrequiredboolean = false,
                        this.getrolelevel()
                        this.showSnackbar("#4caf50","New Role Level Added Successfully..."); 
                    }
                })
                .catch(error => {
                    window.console.log(error);
                }); 
        },

        deleteRoleGroupToUser(rolegroupuserid){
            const req_data={
                rolegroupuserid: rolegroupuserid
            }
            axios
                .post("/AdminUser/removerolelevel",req_data)
                .then(res => {
                    if (res.data.msg == "200") {
                        this.addrolelevel=[];
                        this.getrolelevel()
                        this.showSnackbar("#4caf50","User Role Level Deleted Successfully..."); 
                    } 
                })
                .catch(error => {
                    window.console.log(error);
                }); 
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
     
    },
}
</script>
<style scoped>
.delete-avatar{
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.delete-avatar:hover{
  background-color: #b0bec5;
}
.delete-v-icon{
  padding-left: 8px;
  padding-top: 1px;
  font-size: 15px;
}
.delete-v-icon:hover{
  color: white;
}
</style>